<template>
	<section class="contact">
		<div class="container py-4">
			<div class="">
				<h1 class="text-light text-center py-2">Get In Touch</h1>
				<p class="text-light text-center pb-4 px-4">
					The easiest way to join us is to get in touch by following us on
					social media and registering your interest for membership here
				</p>
				<div class="d-flex">
					<a
						href="https://docs.google.com/forms/d/15NfudfytM0kT2xkJbgKINbedp71aaxnbXWXmP59T-SE/edit"
						target="_blank"
						class="btn join mx-auto px-4"
						>Become A Member Today</a
					>
				</div>
			</div>
			<div class="form-header-text">
				<p class="text-center text-light py-4 px-4">
					Please use the contact form below to reach us if you have any thing
					you need clarification on complaints regarding how to use our platform
					questions that you need answer to
				</p>
			</div>
			<div class="d-flex justify-content-center mb-4">
				<form action="" class="w-50 form" @submit.prevent="sendMessage">
					<div class="d-flex justify-content-center">
						<div class="mb-3 mx-2 w--lg-25">
							<input
								type="name"
								class="form-control form-control-lg form-input text-light"
								id="name"
								placeholder="Full Name"
								v-model="name"
							/>
						</div>
						<div class="mb-3 mx-2 w-lg-25">
							<input
								type="email"
								class="form-control form-control-lg form-input text-light"
								id="email"
								placeholder="Email"
								v-model="email"
							/>
						</div>
					</div>
					<div class="d-flex justify-content-center">
						<div class="mb-3 mx-2 w-100">
							<textarea
								class="form-control form-control-lg form-input text-light"
								id="message"
								rows="3"
								placeholder="Enter your message here"
								v-model="message"
							></textarea>
						</div>
					</div>
					<div class="d-flex">
						<button type="submit" class="btn join mx-auto px-2 fs-5">
							Submit
						</button>
						>
					</div>
				</form>
			</div>
		</div>
	</section>
</template>

<style scoped>
.contact {
	background-color: #02031d;
}

.contact .container {
	height: fit-content;
	padding: 100px 0 !important;
}
.contact h1 {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-size: 68px;
}

.contact p {
	font-family: "inter", sans-serif;
	font-weight: 600;
	font-size: 28px;
}

.btn {
	font-size: 28px;
	background: #e9516e;
	color: #ffffff;
	font-weight: 700;
	width: 450px;
}

.btn:hover {
	background: #ffffff;
	color: #e9516e;
}

.join {
	border-radius: 30px;
	padding: 12px;
}

.form-header-text {
	margin: 50px 0;
}

.form-input {
	height: 70px;
	background-color: #02031d;
	border-radius: none !important;
	outline: none;
	font-family: "inter", sans-serif;
	font-size: 22px;
	font-weight: 500;
	color: #7e7e8c;
}

.form-input:focus {
	background-color: #02031d;
	outline: 0 !important;
}

.form-input::placeholder {
	color: #fff;
	font-size: 18px;
}

.form-control-lg,
.form-control {
	border-radius: 0 !important;
	outline: 0 !important;
}

@media screen and (max-width: 576px) {
	.contact h1 {
		font-size: 40px;
	}

	.contact p {
		font-size: 16px;
	}
	.form {
		width: 100% !important;
	}
	.btn {
		font-size: 16px;
		width: 70%;
	}
}
</style>

<script>
// import Navbar from "../components/Nav-bar.vue";
// import FooterComponent from "@/components/Footer-Component.vue";
import { onMounted } from "vue";

export default {
	name: "ContactView",
	components: {
		// Navbar,
		// FooterComponent,
	},
	setup() {
		onMounted(() => {
			window.scrollTo(0, 0);
		});
	},
};
</script>
