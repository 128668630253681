<template>
	<!-- <Navbar /> -->
	<!--Main Header Banner Section-->
	<section
		class="container-fluid banner-container d-flex align-items-center justify-content-center"
	>
		<div class="container banner">
			<h1 class="text-center fs-md-3">
				Comprehensive <br />
				<span>Trade Union Management</span>
				<br />
				services tailored specifically for the Gaming Industry in Nigeria.
			</h1>
			<p class="text-center">
				With over 50 thousand members and Trade Unions established across all 36
				states, we proudly represent the Gaming Industry in Nigeria.
			</p>
			<div class="d-flex">
				<a
					href="https://docs.google.com/forms/d/15NfudfytM0kT2xkJbgKINbedp71aaxnbXWXmP59T-SE/edit"
					target="_blank"
					class="btn join mx-auto"
					>Join NUGLOW Now</a
				>
			</div>
		</div>
	</section>

	<!--What we Do Section-->
	<section class="container">
		<div class="wwd p-4">
			<h2
				class="wwd-header text-center"
				data-aos="fade-down"
				data-aos-duration="1000"
			>
				What We Do
			</h2>
			<p
				class="wwd-heading_text text-center"
				data-aos="fade-up"
				data-aos-delay="500"
				data-aos-easing="ease-in-out"
				data-aos-duration="1000"
			>
				At NUGLOW, we play a crucial role in safeguarding the interests of all
				stakeholders by diligently monitoring and managing the affairs of
				Lottery and Gaming administration. As a strong and unified Trade Union,
				we ensure fair representation, advocate for workers' rights, and foster
				a thriving and harmonious industry for everyone involved.
			</p>
		</div>
		<div class="wwd_section p-4">
			<div
				class="d-flex flex-md-wrap flex-lg-nowrap flex-column flex-lg-row spacing"
			>
				<img
					class="img-fluid section_image"
					src="../assets/Frame45.png"
					alt="Education Image"
					data-aos="fade-right"
					data-aos-delay="500"
					data-aos-duration="1000"
				/>
				<div
					class="content_text d-flex flex-column justify-content-center p-4"
					data-aos="fade-up"
					data-aos-duration="1000"
				>
					<h3>We Educate Our Members</h3>
					<p>
						We educate our members on organizing strategies through trainings,
						workshops, and discussions. We also help educate the general public
						on labour abuses within the industry through our LIIP program,
						publications, outreach programs, and public events.
					</p>
				</div>
			</div>
			<div
				class="d-flex m-4 flex-lg-nowrap flex-md-wrap-reverse flex-column-reverse flex-lg-row"
			>
				<div
					class="content_text d-flex flex-column justify-content-center p-4"
					data-aos="fade-up"
					data-aos-duration="1000"
				>
					<h3>We Provide Support for workers</h3>
					<p>
						We provide support, advice, and legal and organization connections
						to workers seeking to organize their own workplaces.
					</p>
				</div>
				<img
					class="img-fluid section_image"
					src="../assets/Frame46.png"
					alt="Education Image"
					data-aos="fade-left"
					data-aos-delay="500"
					data-aos-duration="1000"
				/>
			</div>
			<div></div>
		</div>
	</section>

	<!--What we offer Section-->
	<div class="wwo-background text-light">
		<section class="container p-4">
			<div class="wwd p-4">
				<h2
					class="wwd-header text-center text-light"
					data-aos="fade-down"
					data-aos-duration="1000"
				>
					What We Offer
				</h2>
				<p
					class="wwd-heading_text text-center"
					data-aos="fade-up"
					data-aos-delay="500"
					data-aos-easing="ease-in-out"
					data-aos-duration="1000"
				>
					By joining NULAE, you get access to a wide range of benefits in one
					click
				</p>
			</div>
			<div class="wwd_section m-4">
				<div
					class="d-flex m-4 flex-md-wrap flex-lg-nowrap flex-column flex-lg-row spacing"
				>
					<img
						class="img-fluid section_image"
						src="../assets/Frame45.png"
						alt="Education Image"
						data-aos="fade-right"
						data-aos-delay="500"
						data-aos-duration="1000"
					/>
					<div
						class="content_text d-flex flex-column justify-content-center p-4"
						data-aos="fade-up"
						data-aos-duration="1000"
					>
						<h3 class="text-light">Security</h3>
						<p>
							At NUGLOW, security is at the heart of our mission. We empower our
							members with invaluable knowledge and organizing strategies
							through comprehensive trainings, interactive workshops, and
							meaningful discussions. Not only do we prioritize our members'
							welfare, but we also take our commitment to the industry's
							well-being to the next level.
						</p>
						<p>
							Through our groundbreaking LIIP program, insightful publications,
							impactful outreach initiatives, and engaging public events, we
							educate the general public about labor abuses within the industry.
							By fostering awareness and driving positive change, we work
							tirelessly to create a more secure and equitable landscape for all
							stakeholders involved. Together, we strengthen the foundation of
							the Lottery and Gaming sector, ensuring a brighter future for
							everyone.
						</p>
					</div>
				</div>
				<div
					class="d-flex m-4 flex-lg-nowrap flex-md-wrap-reverse flex-column-reverse flex-lg-row"
				>
					<div
						class="content_text d-flex flex-column justify-content-center p-4"
						data-aos="fade-up"
						data-aos-duration="1000"
					>
						<h3 class="text-light">Get into Safety</h3>
						<p>
							Did you know that being a valued member not only gives you a
							strong sense of security but also opens doors to exclusive
							benefits? As part of our commitment to your well-being, membership
							qualifies you for comprehensive health insurance and a micro
							pension plan.
						</p>
						<p>
							Take charge of your health with immediate access to care at over
							4,000 healthcare facilities nationwide. Your future is equally
							important, and with our pension plan, you can confidently save for
							a prosperous tomorrow.
						</p>
						<p>
							Join us today and experience the peace of mind that comes with
							being part of a community that truly cares about your safety and
							future. Together, we build a foundation of security and prosperity
							for you and all our esteemed members.
						</p>
					</div>
					<img
						class="img-fluid section_image"
						src="../assets/Frame49.png"
						alt="Education Image"
						data-aos="fade-left"
						data-aos-delay="500"
						data-aos-duration="1000"
					/>
				</div>
				<div
					class="d-flex m-4 flex-md-wrap flex-lg-nowrap flex-column flex-lg-row spacing"
				>
					<img
						class="img-fluid section_image"
						src="../assets/Frame50.png"
						alt="Education Image"
						data-aos="fade-right"
						data-aos-delay="500"
						data-aos-duration="1000"
					/>
					<div
						class="content_text d-flex flex-column justify-content-center p-4"
						data-aos="fade-up"
						data-aos-duration="1000"
					>
						<h3 class="text-light">
							Foster a Positive Industry Image through Responsible Practices
						</h3>
						<p>
							At NUGLOW, we take pride in promoting a positive societal
							perception of the gaming industry through responsible practices.
							With a strong commitment to ethical conduct and fair play, we
							strive to elevate the industry's reputation and impact.
						</p>
						<p>
							Life becomes more enjoyable when games bring joy and excitement.
							Embrace the thrill of play, but remember, life is a precious gift
							with countless opportunities. Cherish every moment, and let our
							responsible approach to gaming enhance your experience.
						</p>
						<p>
							Together, we create a harmonious balance between fun and
							responsibility, ensuring a bright and fulfilling journey for all.
							Join us today and embrace a world where gaming brings smiles and
							positivity to every corner of society.
						</p>
					</div>
				</div>
				<div
					class="d-flex m-4 flex-lg-nowrap flex-md-wrap-reverse flex-column-reverse flex-lg-row"
				>
					<div
						class="content_text d-flex flex-column justify-content-center p-4"
						data-aos="fade-up"
						data-aos-duration="1000"
					>
						<h3 class="text-light">Get into Safety</h3>
						<p>
							Do you know that being a member qualifies you for a health
							insurance and a micro pension plan? Get immediate access to care
							in over 4,000 healthcare facilities nationwide & save your pension
							for your future today.
						</p>
					</div>
					<img
						class="img-fluid section_image"
						src="../assets/Frame51.png"
						alt="Education Image"
						data-aos="fade-left"
						data-aos-delay="500"
						data-aos-duration="1000"
					/>
				</div>
			</div>
		</section>
	</div>

	<!--Why we are Unique Section-->
	<section class="container">
		<div class="wwd m-4 p-4" style="padding: 10px">
			<h2
				class="wwd-header text-center"
				data-aos="fade-down"
				data-aos-duration="1000"
			>
				Embrace Our Uniqueness: Elevating the Industry Together
			</h2>
			<p
				class="wwd-heading_text text-center"
				data-aos="fade-up"
				data-aos-delay="500"
				data-aos-easing="ease-in-out"
				data-aos-duration="1000"
			>
				At NUGLOW, we stand apart with our unwavering commitment to elevate the
				gaming industry and empower its workforce. Here's why we are unique:
			</p>
		</div>
		<div class="wwd_section m-4">
			<div
				class="d-flex m-4 flex-md-wrap flex-lg-nowrap flex-column flex-lg-row spacing"
			>
				<img
					class="img-fluid section_image"
					src="../assets/Frame531.png"
					alt="Education Image"
					data-aos="fade-right"
					data-aos-delay="500"
					data-aos-duration="1000"
				/>
				<div
					class="content_text d-flex flex-column justify-content-center p-4"
					data-aos="fade-up"
					data-aos-duration="1000"
				>
					<div class="d-flex">
						<div class="w-auto">
							<BIconCheckCircleFill class="check-icon mx-2" />
						</div>
						<p>
							<span class="fw-bold my-2"
								>Better Working Conditions for Workers:</span
							><br />
							We prioritize the well-being of our members, ensuring better
							working conditions and advocating for fair treatment. By
							collectively addressing labor concerns, we foster an environment
							where workers thrive and flourish.
						</p>
					</div>
					<div class="d-flex">
						<div class="w-auto">
							<BIconCheckCircleFill class="check-icon mx-2" />
						</div>
						<p>
							<span class="fw-bold my-2"
								>Industry-Specific Training for Professional Excellence:</span
							><br />
							Our tailored training programs equip workers with specialized
							skills and knowledge, elevating their professionalism and
							expertise. Through continuous learning, we cultivate a highly
							skilled workforce, ready to tackle industry challenges with
							confidence.
						</p>
					</div>
					<div class="d-flex">
						<div class="w-auto">
							<BIconCheckCircleFill class="check-icon mx-2" />
						</div>
						<p>
							<span class="fw-bold my-2">Ensuring Safety and Dignity:</span
							><br />
							Proper identification safeguards our members from undue harassment
							by security agencies. We champion dignity and respect, creating a
							secure environment for everyone associated with the industry.
						</p>
					</div>
					<div class="d-flex">
						<div class="w-auto">
							<BIconCheckCircleFill class="check-icon mx-2" />
						</div>
						<p>
							<span class="fw-bold my-2"
								>Promoting Responsible Gaming Ethos:</span
							><br />
							We take pride in driving responsible gaming practices across the
							industry. By promoting ethical conduct and responsible play, we
							strive to enhance the industry's image and societal impact.
						</p>
					</div>
				</div>
			</div>
			<div
				class="d-flex m-4 flex-lg-nowrap flex-md-wrap-reverse flex-column-reverse flex-lg-row"
			>
				<div
					class="content_text d-flex flex-column justify-content-center p-4"
					data-aos="fade-up"
					data-aos-duration="1000"
				>
					<div class="d-flex">
						<div class="w-auto">
							<BIconCheckCircleFill class="check-icon mx-2" />
						</div>
						<p>
							<span class="fw-bold my-2"
								>Micro-Pension Scheme, Health Insurance & Credit
								Facilities:</span
							><br />
							Secure your future with our Micro-Pension Scheme and enjoy peace
							of mind with comprehensive health insurance coverage.
							Additionally, access credit facilities to support your financial
							needs as you progress in your career.
						</p>
					</div>
					<div class="d-flex">
						<div class="w-auto">
							<BIconCheckCircleFill class="check-icon mx-2" />
						</div>
						<p>
							<span class="fw-bold my-2">Free Business and Tax Advisory::</span
							><br />
							As a valued member, you gain access to free expert advice on
							business matters and tax-related concerns. We are committed to
							your success and provide the guidance you need to flourish.
						</p>
					</div>
					<div class="d-flex">
						<div class="w-auto">
							<BIconCheckCircleFill class="check-icon mx-2" />
						</div>
						<p>
							<span class="fw-bold my-2"
								>Promoting Better Operator & Staker Relations:</span
							><br />
							We foster a harmonious and respectful relationship between
							operators and stakeholders. Through open dialogue and
							collaborative efforts, we build bridges that benefit the entire
							gaming ecosystem.
						</p>
					</div>
					<div class="d-flex">
						<div class="w-auto">
							<BIconCheckCircleFill class="check-icon mx-2" />
						</div>
						<p>
							<span class="fw-bold my-2"
								>Protecting Operators from Negative Policies or
								Exploitation:</span
							><br />
							Our advocacy extends to safeguarding operators from adverse
							policies and any form of exploitation by workers. We strive for a
							balanced and fair environment where everyone thrives.
						</p>
					</div>
				</div>
				<img
					class="img-fluid section_image d-none d-lg-block"
					src="../assets/Frame531.png"
					alt="Education Image"
					data-aos="fade-left"
					data-aos-delay="500"
					data-aos-duration="1000"
				/>
			</div>
		</div>
	</section>
	<!-- <FooterComponent /> -->
</template>

<script>
// import Navbar from "../components/Nav-bar.vue";
// import FooterComponent from "@/components/Footer-Component.vue";
import { onMounted } from "vue";

export default {
	name: "HomeView",
	components: {
		// Navbar,
		// FooterComponent,
	},
	setup() {
		onMounted(() => {
			window.scrollTo(0, 0);
		});
	},
};
</script>

<style scoped>
.banner-container {
	background-color: #02031d;
	color: white;
	height: 95vh !important;
	padding: 9% 0;
}
container {
	width: none !important;
}

.wwo-background {
	background-color: #02031d;
}
.banner h1 {
	font-family: "Poppins", sans-serif;
	font-size: 60px;
	font-weight: 700;
}

.banner h1 span {
	background: linear-gradient(90deg, #ffffff -0.23%, #e9516e 93.19%);
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
}

.banner p {
	font-size: 25px;
}

.spacing {
	margin-bottom: 20% !important;
	margin-top: 20% !important;
}

.banner .btn {
	font-size: 28px;
	background: #e9516e;
	color: #ffffff;
	font-weight: 700;
	width: 300px;
}

.btn:hover {
	background: #ffffff;
	color: #e9516e;
}

.join {
	border-radius: 30px;
	padding: 12px;
}

.wwd-header {
	font-size: 50px;
	font-weight: 700;
	font-family: "Poppins", sans-serif;
	color: #02031d;
	margin-top: 10% !important;
}

.wwd-heading_text {
	font-size: 22px;
}

.check-icon {
	color: #e9516e;
	font-size: 30px !important;
}

.content_text h3 {
	font-size: 28px;
	font-weight: 700;
	color: #02031d;
}

@media screen and (max-width: 576px) {
	.banner-container {
		height: fit-content !important;
	}
	.banner h1 {
		font-size: 35px;
	}

	.banner p {
		font-size: 18px;
	}

	.wwd-header {
		font-size: 30px;
	}

	.wwd-heading_text {
		font-size: 20px;
	}

	.join {
		font-size: 18px !important;
	}
}
</style>
