<template>
	<section class="banner-footer-container">
		<footer class="container text-center text-light">
			<img src="../assets/logo.png" alt="Nulae Logo" class="logo" />
			<h4 class="fw-bold">National Union of Gaming and Lottery Workers </h4>
			<p>Gaming for social good</p>
			<p class="">
				2nd Floor, King's Deck, Lekki Alternative<br />
				Route Chevron Drive, Lagos, Nigeria.
			</p>
			<div class="my-2">
				<p>
					<BIconTwitter class="mx-4" />
					<BIconFacebook class="mx-4" />
					<BIconInstagram class="mx-4" />
				</p>
			</div>
		</footer>
	</section>
</template>


<script>
export default {
	name: "Footer-Component",
};
</script>

<style scoped>
.banner-footer-container {
	background-color: #02031d;
	padding: 30px 30px;
	height: fit-content;
}
.logo {
	width: 100px;
}

@media screen and (max-width: 576px) {
	.logo {
		width: 50px;
	}

	.banner-footer-container h4 {
		font-size: 18px;
	}

	.banner-footer-container p {
		font-size: 12px;
	}
}
</style>
