// import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Textra from 'vue-textra'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'animate.css';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import AOS from 'aos'
import 'aos/dist/aos.css'


createApp(App).use(AOS).use(BootstrapIconsPlugin).use(Textra).use(router).mount('#app')
