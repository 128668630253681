<template>
  <Navbar />
	<router-view></router-view>
  <FooterComponent />
</template>

<script>
import AOS from 'aos';
import Navbar from '@/components/Nav-bar.vue';
import FooterComponent from '@/components/Footer-Component.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    FooterComponent
    // HomeView,
  },
  mounted() {
    AOS.init();
  }
};
</script>

<style>
body {
  width: 100vw;
}
#app {
	font-family: 'OpenSans', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  max-width: 100%;
  
}

</style>
