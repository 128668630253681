
<template>
	<nav class="navbar navbar-expand-lg bg-body-tertiary">
		<div class="container">
			<router-link :to="{name: 'home'}" class="navbar-brand">
				<img src="../assets/logo.png" alt="" class="img img-fluid"/>
			</router-link>
			<button
				class="navbar-toggler border border-light"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<BIconList class="text-light"/>
			</button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav mb-2 mb-lg-0 d-flex justify-content-end w-100">
					<li class="nav-item">
						<router-link :to="{name: 'about'}" class="nav-link text-light text-sm-left mx-2" aria-current="page"
							>About Us</router-link
						>
					</li>
					<li class="nav-item">
						<router-link :to="{name: 'contact'}" class="nav-link text-light text-md-left mx-2">Contact Us</router-link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>

<style scoped>
.navbar {
	background-color: #02031d !important;
}
.nav-link {
	font-size: 14px;
	text-align: center;
}

.img {
	width: 80px;
}
</style>
